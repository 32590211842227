import React from "react"
import { StoryGroup } from "components"

export const LinkMap = {
  AntiPattern: "883bcf4868cb",
  "AntiPattern-Development": "883bcf4868cb",
  "AntiPattern-Mimari": "883bcf4868cb",
  "AntiPattern-Yönetimsel": "883bcf4868cb",

  "Spaghetti Code": "25ea2cbbf90",
  "Spaghetti-GoTo": "25ea2cbbf90",
  "Spaghetti-Assembly": "25ea2cbbf90",

  "Lava Flow": "3fa70cab7b9f",
  "Lava Flow-Ekstra Bakım": "3fa70cab7b9f",
  "Lava Flow-Programming": "3fa70cab7b9f",
  "Lava Flow-Data": "3fa70cab7b9f",
  "Lava Flow-Tech": "3fa70cab7b9f",

  "Golden Hammer": "697510d3e90c",
  "Maslow law of the instrument": "697510d3e90c",

  "Reinvent The Wheel": "f7470d76442e",
  Reusability: "f7470d76442e",

  "Blob Anti-Pattern": "7f85bf88ee24",
  "Observer Pattern": "7f85bf88ee24",
  "Mediator Pattern": "7f85bf88ee24",

  "Analysis Paralysis": "7fd651de235e",
  "Circular dependency": "b408c2a161cf",
}

const stories = [
  {
    title: "AntiPattern Nedir",
    postId: "883bcf4868cb",
  },
  {
    title: "Spaghetti Code",
    postId: "25ea2cbbf90",
  },
  {
    title: "Lava Flow Programming",
    postId: "3fa70cab7b9f",
  },
  {
    title: "Golden Hammer",
    postId: "697510d3e90c",
  },
  {
    title: "Reinvent The Wheel",
    postId: "f7470d76442e",
  },
  {
    title: "Blob",
    postId: "7f85bf88ee24",
  },
  {
    title: "Analysis Paralysis",
    postId: "7fd651de235e",
  },
  {
    title: "Circular dependency",
    postId: "b408c2a161cf",
  },
  {
    title: "Magic Numbers, Strings",
    postId: "1421cc64a562",
  },
  {
    title: "Silver Bullet",
    postId: "9867bd3cda4d",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Mimari Anti-Patterns",
  path: "antipatterns",
}

const AntiPatternsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default AntiPatternsPage
